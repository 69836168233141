/* eslint-disable */
// Sidebar Routers
export const menus = [
   {
      path: '/',
      name: "message.home",
		icon: "home",
		type: "sub_menu",
   },
   // {
   //    name: "message.about",
   //    children_menus:null,
   //    path: "/"
   // },
   {
      name: "message.services",
      children_menus:null,
      path: "/services"
   },
   // {
   //    name: "message.news",
   //    children_menus:null,
   //    path: "/"
   // },
   // {
   //    name: "message.careers",
   //    children_menus:null,
   //    path: "/careers"
   // },
   // {
   //    path: '/contact',
   //    name:"message.contactUs",
   //    icon: 'perm_contact_calendar',
   //    children: null
   // },
   
]
