//-----------------------| Sidebar Module |-------------------//

import { menus } from './data.js';

const state = {
	menus,
	sidebarOpen: false
}

const getters = {
	menus: state => {
		return state.menus;
	},
	sidebarOpen: state =>{
		return state.sidebarOpen;
	}
}

const actions = {
   toggleSidebar(context, payload) {
      context.commit('toggleSidebarHandler', payload);
   },
	
}

const mutations = {
	toggleSidebarHandler(state, payload) {
	  state.sidebarOpen = payload;
	},
	
}

export default {
	state,
	getters,
	actions,
	mutations
}