<template>
   <div class="page-title-bar">
      <div class="container">
         <h1 class="mb-4 black--text"> {{heading}}</h1>
      </div>
   </div>
</template>

<script>
export default {
   props:['heading','subHeading']
}
</script>