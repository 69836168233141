<template>
	<div>
		<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7544.381158830702!2d72.8375!3d19.011322!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb0d98d4080e115a!2sSunshine%20Tower!5e0!3m2!1sen!2sin!4v1641283384083!5m2!1sen!2sin" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
	</div>
</template>

<script>
	export default {
		name: "GoogleMap",
		data() {
			return {
				
			};
		}
	}
</script>