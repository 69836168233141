/**
 * VueShop Global Components
 */
import HeaderV3 from './components/Layouts/Header/HeaderV3.vue';
import FixedHeader from './components/Layouts/Header/FixedHeader.vue';
import FooterV1 from './components/Layouts/Footer/FooterV1.vue';
import SocialShare from './components/Global/SocialShare';
import GoogleMap from "./components/Global/GoogleMap";
import PageTitle from './components/Global/PageTitle';
import Sidebar from './components/Layouts/Sidebar/Sidebar';


const GlobalComponents = {
   install(Vue) {
      Vue.component('embHeaderV3', HeaderV3);
      Vue.component('embFixedHeader', FixedHeader);
      Vue.component('embFooterV1', FooterV1);
      Vue.component('embSocialShare', SocialShare);
      Vue.component('embGoogleMap', GoogleMap);
      Vue.component('embPageTitle', PageTitle);
      Vue.component('embSidebar', Sidebar);
   }
}

export default GlobalComponents