<template>
	<v-app>		
		<router-view></router-view>
	 	
	</v-app>
</template>

<script>
import { mapGetters } from "vuex";
import WOW from "wow.js";
export default {
	metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Chainsense Pvt. Ltd',
	  meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'foo' }
	  ]
    },
	computed: {
		...mapGetters(["rtlLayout"]),
		mobileMenu: {
			get() {
				return this.$store.getters.sidebarOpen;
			},
			set(val) {
				this.$store.dispatch("toggleSidebar", val);
			}
		}
	},
	/**
	 * Method To set the Rtl While page is opened
	*/
	mounted() {
		var wow = new WOW({
      boxClass: "wow", // animated element css class (default is wow)
      animateClass: "animated", // animation css class (default is animated)
      offset: 0, // distance to the element when triggering the animation (default is 0)
      mobile: true, // trigger animations on mobile devices (default is true)
      live: true, // act on asynchronously loaded content (default is true)
      scrollContainer: null, // optional scroll container selector, otherwise use window,
      resetAnimation: true, // reset animation on end (default is true)
    });
    wow.init();
	},
	methods: {
		/**
		 * Method To Toggle The RTL Layout
		 */
		
	}
}
</script>


