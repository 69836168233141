import UserPanel from 'Container/UserPanel';

const HomeV1 = () => import('Views/HomeV1');
const AboutUs = () => import('Views/AboutUs');
const Services = () => import('Views/Services');
const News = () => import('Views/News');
const Careers = () => import('Views/Careers');
const TermCondiition = () => import('Views/TermsAndCondition');
const Faq = () => import('Views/Faq');
const ContactUs = () => import('Views/Contact');
const PrivacyPolicy = () => import('Views/PrivacyPolicy');


export default {
	path: '/',
	component: UserPanel,
	redirect:'/',
	children:[
		{ 
			path: '/',
			component: HomeV1 ,
			meta: {
				header: 1
			 }
		},
		
	
		{ 
			path: '/about',  
			component: AboutUs, 
			name:'about'
		},
		{ 
			path: '/services',  
			component: Services, 
			name:'services'
		},
		{ 
			path: '/careers',  
			component: Careers, 
			name:'careers'
		},
		{ 
			path: '/news',  
			component: News, 
			name:'news'
		},
		{ 
			path: '/term-condition',  
			component: TermCondiition, 
			name:'term-condition'
		},
		{ 
			path: '/faq',  
			component: Faq, 
			name:'Faq'
		},
		
		{ 
			path: '/contact',  
			component: ContactUs, 
			name:'ContactUs'
		},

		{ 
			path: '/privacy-policy',  
			component: PrivacyPolicy, 
			name:'PrivacyPolicy'
		},
		
	]
}