<template>
  <v-app>
    <Particles
      id="tsparticles"
      :options="{
        background: false,
        fpsLimit: 60,
        interactivity: false,
        particles: {
          color: {
            value: '#000000',
          },
          links: {
            color: '#000000',
            distance: 150,
            enable: true,
            opacity: 0.5,
            width: 1,
          },
          collisions: {
            enable: true,
          },
          move: {
            enable: true,
            speed: 2,
            direction: 'none',
            random: false,
            straight: false,
            out_mode: 'out',
            bounce: false,
            attract: {
              enable: false,
              rotateX: 3000,
              rotateY: 3000,
            },
          },
          number: {
            density: {
              enable: true,
              area: 800,
            },
            value: 50,
          },
          opacity: {
            value: 0.1,
          },
          shape: {
            type: 'circle',
          },
          size: {
            random: true,
            value: 5,
          },
        },
        detectRetina: true,
      }"
    />
    <div class="position-relative">
          <vue-snotify></vue-snotify>
    <div class="headers">
      <emb-header-v3></emb-header-v3>
    </div>

    <!-- Menu for mobile -->
    <v-navigation-drawer
      v-model="mobileMenu"
      temporary
      absolute
      dark
      class="sidebar-bg"
    >
      <emb-sidebar></emb-sidebar>
    </v-navigation-drawer>
    <router-view></router-view>
    <emb-footer-V1
      title="About company"
      description="Our aim at Chain Sense begins from expanding the awareness, spread and applications of Blockchain in our everyday lives to help reshape how we use and let others use our data."
    ></emb-footer-V1>
    </div>

  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["rtlLayout"]),
    mobileMenu: {
      get() {
        return this.$store.getters.sidebarOpen;
      },
      set(val) {
        this.$store.dispatch("toggleSidebar", val);
      },
    },
  },
  /**
   * Method To set the Rtl While page is opened
   */
  mounted() {
    if (this.rtlLayout) {
      this.$vuetify.rtl = this.rtlLayout;
    }
  },
  methods: {
    /**
     * Method To Toggle The RTL Layout
     */
    toggleRTLLayout() {
      this.$vuetify.rtl = !this.rtlLayout;
      this.$store.dispatch("changeRtlLayout");
    },
  },
};
</script>
