var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('Particles',{attrs:{"id":"tsparticles","options":{
      background: false,
      fpsLimit: 60,
      interactivity: false,
      particles: {
        color: {
          value: '#000000',
        },
        links: {
          color: '#000000',
          distance: 150,
          enable: true,
          opacity: 0.5,
          width: 1,
        },
        collisions: {
          enable: true,
        },
        move: {
          enable: true,
          speed: 2,
          direction: 'none',
          random: false,
          straight: false,
          out_mode: 'out',
          bounce: false,
          attract: {
            enable: false,
            rotateX: 3000,
            rotateY: 3000,
          },
        },
        number: {
          density: {
            enable: true,
            area: 800,
          },
          value: 50,
        },
        opacity: {
          value: 0.1,
        },
        shape: {
          type: 'circle',
        },
        size: {
          random: true,
          value: 5,
        },
      },
      detectRetina: true,
    }}}),_c('div',{staticClass:"position-relative"},[_c('vue-snotify'),_c('div',{staticClass:"headers"},[_c('emb-header-v3')],1),_c('v-navigation-drawer',{staticClass:"sidebar-bg",attrs:{"temporary":"","absolute":"","dark":""},model:{value:(_vm.mobileMenu),callback:function ($$v) {_vm.mobileMenu=$$v},expression:"mobileMenu"}},[_c('emb-sidebar')],1),_c('router-view'),_c('emb-footer-V1',{attrs:{"title":"About company","description":"Our aim at Chain Sense begins from expanding the awareness, spread and applications of Blockchain in our everyday lives to help reshape how we use and let others use our data."}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }