export default {
	"home":"Home",
	"about":"About Us",
	"services":"Services",
	"news":"News",
	"careers":"Careers",
	"contactUs":"Contact Us",
	"ourCoverage":"Our Coverage",
	"blockchain":"Blockchain",
	"centralisedExchanges":"Centralised Exchanges",
	"swaps":"Swaps",
	"DExs":"DExs",
	"Contracts_BusinessLogics":"Contracts/Business Logics",
	"servingHeading":"Serving over 500k+ Users through services",
	"termAndCondition":"Terms & Conditions",
	"privacyPolicy":"Privacy Policy",
	"faq":"Faq",
	"uk":"United Kindom",
	"dubai":"Dubai",
	"southKorea":"South Korea",
	"india":"India",
	"locations":"Locations",
	"subscribe":"Newsletters",
	
}