<template>
  <div class="footerV1-wrap section-gap">
    <v-container grid-list-xl py-0>
      <div class="collaborate d-flex">
        <v-row align="center" justify="center">
          <v-col cols="10">
            <v-row align="center" justify="space-between">
              <v-col cols="9">
                <h3>Let's Collaborate!</h3>
                <p>Building a new age world - One chain at a time.</p>
              </v-col>
              <v-col cols="3">
                <v-btn
                  dark
                  x-large
                  :href="'mailto:support@' + this.hostName"
                  target="_blank"
                  >Enquire Now</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>

      <div class="footer-top">
        <v-layout row wrap>
          <v-flex xs12 sm6 md3 lg3 xl3>
            <div class="about-wrap">
              <img alt="site-logo" height="34" :src="appLogo" width="224" />
              <div class="white--text">{{ description }}</div>
            </div>
          </v-flex>
          <v-flex xs12 sm6 md3 lg3 xl3>
            <h6 class="white--text mb-4">{{ $t("message.about") }}</h6>
            <div class="abt-menu">
              <ul class="list-unstyled pl-0">
                <li v-for="(list, key) in about" :key="key">
                  <router-link :to="list.path">{{
                    $t(list.menuItem)
                  }}</router-link>
                </li>
              </ul>
            </div>
          </v-flex>
          <v-flex xs12 sm6 md3 lg3 xl3>
            <h6 class="white--text mb-4">{{ $t("message.locations") }}</h6>
            <div class="features-menu">
              <ul class="list-unstyled pl-0">
                <li v-for="(list, key) in locations" :key="key">
                  <a :href="list.href">{{ list.menuItem }}</a>
                </li>
              </ul>
            </div>
          </v-flex>
          <v-flex xs12 sm6 md3 lg3 xl3>
            <div class="policy-menu">
              <ul class="d-flex list-unstyled pl-0">
                <li class="mr-3" v-for="(list, key) in socialLinks" :key="key">
                  <a :href="list.path" target="_blank" class="mr-3">
                    <h4 class="white--text">
                      <i v-if="list.icon" :class="list.icon"></i>
                    </h4>
                  </a>
                </li>
              </ul>
              
              <template v-if="this.hostName == 'chainsense.xyz'">
				  <h5 class="white--text">Address:</h5>
                <p class="white--text">
                  71-75 Shelton Street,<br />
                  Covent Garden,<br />
                  London, England, WC2H 9JQ
                </p>
              </template>
              <template v-if="this.hostName == 'chainsense.in'">
				  <h5 class="white--text">Address:</h5>
                <p class="white--text">
                  Floor No. 25 Sunshine Tower, Plot No, 616,<br />
                  Senapati Bapat Marg,<br />
                  Dadar West, Mumbai,<br />
                  Maharashtra 400013.
                </p>
              </template>
              <template v-if="this.hostName == 'chainsense.africa'">
				  <h5 class="white--text">Address:</h5>
                <p class="white--text">
                  27,Bantry Road, bryaneven primary,<br />
                  Bryanston,<br />
                  Gauteng. 2191 South Africa
                </p>
              </template>
            </div>
          </v-flex>
        </v-layout>
      </div>
      <hr />
      <div class="footer-bottom">
        <div class="footer-bottom-content px-3">
          <v-layout row wrap align-center justify-space-between>
            <v-flex xs12 sm12 md6 lg6 xl6 class="text-md-left text-center">
              <span class="white--text"
                >&copy; 2021-22 {{ copyrightText }}</span
              >
            </v-flex>
            <v-flex xs12 sm12 md6 lg6 xl6 py-3 px-2>
              <div class="text-md-right text-center"></div>
            </v-flex>
          </v-layout>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import AppConfig from "Constants/AppConfig";

export default {
  props: ["title", "description"],

  data() {
    return {
      address: "",
      appLogo: AppConfig.appLogoDark,
      hostName: "",
      about: [
        {
          menuItem: "message.about",
          path: "/",
        },
        {
          menuItem: "message.termAndCondition",
          path: "/",
        },
        {
          menuItem: "message.privacyPolicy",
          path: "/",
        },
        {
          menuItem: "message.faq",
          path: "/",
        },
        {
          menuItem: "message.contactUs",
          path: "/",
        },
      ],
      locations: [
        {
          menuItem: "UK",
          path: "/",
          href: "https://chainsense.xyz/",
        },
        {
          menuItem: "Dubai",
          path: "/",
          href: "https://chainsense.ae/",
        },
        {
          menuItem: "India",
          path: "/",
          href: "https://chainsense.in/",
        },
        {
          menuItem: "South Africa",
          path: "/",
          href: "https://chainsense.africa/",
        },
		{
          menuItem: "South Korea",
          path: "/",
          href: "https://chainsense.kr/",
        },
      ],
      socialLinks: [
        {
          menuItem: "Facebook",
          icon: "fa fa-facebook",
          path: "https://www.facebook.com/ChainSense/",
        },
        {
          menuItem: "Twitter",
          icon: "fa fa-twitter",
          path: "https://twitter.com/ChainSense",
        },
        {
          menuItem: "Medium",
          icon: "fa fa-medium",
          path: "http://chainsense.medium.com",
        },
        {
          menuItem: "Linkedin",
          icon: "fa fa-linkedin",
          path: "https://www.linkedin.com/company/chainsense",
        },
      ],
      copyrightText: AppConfig.copyrightText,
    };
  },
  mounted() {
    this.hostName = window.location.host;
  },
};
</script>
