//=======================| App Configuration |========================//
export default {
	appLogo: '/static/images/logo.png', 
	appLogoDark: '/static/images/logo-dark.png',                         // App Logo,
	brand: 'Chainsense',// Brand Name
	copyrightText: 'All right reserved for Chain Sense ltd.',     // Copyright Text
	//theme color
	color: {
      primary: '#707070',
      accent : '#000000',
	},
}
